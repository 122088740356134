<template>
    <div class="page">
        <template-wizard :item="item" v-if="item"></template-wizard>
    </div>
</template>

<script>
import TemplateWizard from "@/views/app/templates/components/template-wizard";

export default {
  name: "edit",
  components: {TemplateWizard},

  data() {
    return {
      item: null
    }
  },

  mounted() {
    this.$talker.api(`templates/${this.$route.params.template_id}`)
      .then(res => {
        this.item = res.data;
      })
  },

}
</script>

<style scoped lang="scss">

</style>